import {
  extractHomeContentData,
  HomeLayoutProps,
} from "@app/layouts/Home/Elements/utils";
import { configHome, configTeaser, DataSource } from "@pub/config";
import placeholders from "@pub/placeholders";
import {
  Button,
  Divider,
  LatestArticle,
  ResponsiveImage,
  Typography,
} from "@sphtech/dmg-design-system";
import cx from "classnames";
import { ReactNode } from "react";

import styles from "./homeContentBlockWOTY.module.css";

const configHomeContentBlockWOTY = {
  heading: "Women of the Year 2025",
  description:
    "Do you know an inspiring individual who has made a significant mark in their field and on society? Nominate them for our 2025 Women of the Year and Young Woman Achiever awards! Nomination ends 17 Feb 2025.",
  viewMore: {
    text: "Read More",
  },
  landscapeImageWidth: 315,
  portraitImageWidth: 315,
};

type Props = {
  data: HomeLayoutProps["data"];
  dataSource: DataSource;
  alternateBg?: boolean;
};

export default function HomeContentBlockWOTY({
  data,
  dataSource,
}: Props): ReactNode {
  const ContentBlockItems = extractHomeContentData(data, dataSource);

  return (
    !!ContentBlockItems?.items.length && (
      <div className={cx(styles.container)}>
        {ContentBlockItems.backgroundImage && (
          <div className={styles.backgroundImage}>
            <ResponsiveImage
              {...ContentBlockItems.backgroundImage}
              fill="cover"
              loading="lazy"
              displayWidth={1000}
              mobileUp={{
                ...ContentBlockItems.backgroundImage,
                displayWidth: 1920,
              }}
            />
          </div>
        )}
        <div
          className={cx(
            styles.bodyContainer,
            styles.gridContainer,
            styles.gutter,
          )}
        >
          <div className={styles.textContentContainer}>
            <Typography.Disclaimer>
              {configHomeContentBlockWOTY.heading}
            </Typography.Disclaimer>
            <Typography.Heading.SubSectionTitle>
              {ContentBlockItems.title}
            </Typography.Heading.SubSectionTitle>
            <Typography.Body>
              {configHomeContentBlockWOTY.description}
            </Typography.Body>
            <div className={styles.cta}>
              <Button variant="secondary" href={ContentBlockItems.url}>
                {configHomeContentBlockWOTY.viewMore.text}
              </Button>
            </div>
          </div>
          <div className={styles.divider}>
            <Divider size="story" />
          </div>
          <div className={cx(styles.teaserContainer)}>
            {ContentBlockItems.items.map((contentBlockItem, index) => (
              <div key={index} className={styles.content}>
                <LatestArticle
                  {...contentBlockItem}
                  mode={"landscape"}
                  mobileMode={configHome.latestArticle.mobileMode}
                  mobileModeOriginal={
                    configHome.latestArticle.mobileModeOriginal
                  }
                  placeholders={placeholders}
                  landscapeImageWidth={
                    configHomeContentBlockWOTY.landscapeImageWidth
                  }
                  portraitImageWidth={
                    configHomeContentBlockWOTY.portraitImageWidth
                  }
                  displayPublishDate={configTeaser.publishDate}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  );
}
